.aboutBlock{
    padding-top: 196px;
    padding-bottom: 230px;
}
.aboutBlock .container > label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.5;
    margin-bottom: 27px;
    text-transform: uppercase;
    display: block;
}
.aboutBlock h3{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 73px;

}
.aboutBlock .textBlock .animatedCircleButton{
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #191919;
    border-radius: 50%;
    margin-left: 295px;
}
.aboutBlock .textBlock{
    justify-content: space-between;
}
.aboutBlock .textBlock .text{
    width: 390px;
}
.aboutBlock .textBlock .text .row{
    justify-content: space-between;
    margin-bottom: 38px;
}
.aboutBlock .textBlock .text > p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 0em;
    text-align: left;

}
.aboutBlock .textBlock .bigNumber h2{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
}
.aboutBlock .textBlock .bigNumber span{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;

}
.aboutBlock .textBlock .bigNumber p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.animatedCircleButton{
    position: relative;
    overflow: hidden;
}
.animatedCircleButton p{
    z-index: 10;
    transform: translate3d(0, 0,0) !important;
}
.buttonBack{
    width: 150%;
    height: 200%;
    background-color: #388E3C;
    border-radius: 50%;
    top: -50%;
    left: -25%;
    transform: translate3d(0,-76%,0);
    position: absolute;
    transition-duration: 300ms;
    transition: background-color ease-in-out .25s;
}

@media (max-width: 1199px) {
    .aboutBlock{
        padding-top: 146px;
        padding-bottom: 131px;
    }
    .aboutBlock h3{
        margin-bottom: 33px;
    }
    .aboutBlock .textBlock button{
        margin-left: 115px;
        margin-top: 28px;
    }
    .aboutBlock .textBlock .text{
        margin-right: 61px;
        width: 390px;
    }
    .servicesList{
        margin-top: 74px;
    }
    .aboutBlock .textBlock .animatedCircleButton{
        margin-left: 115px;
    }

}

@media (max-width: 899px) {
    .aboutBlock{
        padding-top: 98px;
        padding-bottom: 93px;
    }
    .aboutBlock .container > label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 23px;
    }
    .aboutBlock h3{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 30px;
    }
    .aboutBlock .textBlock .bigNumber h2{
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
    }
    .aboutBlock .textBlock .bigNumber h2 span{
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
    .aboutBlock .textBlock .bigNumber p{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        width: min-content;

    }
    .aboutBlock .textBlock .animatedCircleButton{
        margin-left: 40px;
        margin-top: 13px;
        width: 200px;
        height: 200px;
    }
    .aboutBlock .textBlock .text{
        width: 280px;
        margin-right: 0;
    }
    .aboutBlock .textBlock .text .row{
        width: 245px;
        margin-bottom: 30px;
    }
    .aboutBlock .textBlock .text > p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

    }
}
@media (max-width: 599px) {
    .aboutBlock{
        padding-top: 78px;
        padding-bottom: 73px;
    }
    .aboutBlock .textBlock{
        flex-direction: column-reverse;
    }
    .aboutBlock .textBlock .text .row{
        width: 245px;
    }
    .aboutBlock .textBlock .text > p{
        width: 300px;
        margin-bottom: 22px;
    }


}