.mainScreens{
    padding-bottom: 182px;
}
.mainScreens.gremm{
    padding-bottom: 150px;
}
.mainScreens label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    color: #00000080;
    text-transform: uppercase;
    margin-bottom: 38px;
    display: block;
}
.mainScreens h3{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 81px;
}
.mainScreens p{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 91px;
}
.mainScreens .row{
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}
.mainScreens .row .largeImg img{
    width: 100%;
    height: 1200px;

}
.mainScreens .row .largeImg{
    height: 1200px;
}

.mainScreens.gremm:nth-child(10) .largeImg,
.mainScreens.gremm:nth-child(10) .largeImg img{
    height: 915px;
}
.mainScreens .row .mediumImg{
    width: calc(50% - 10px);
    height: 590px;
}
.mainScreens .row .mediumImg img{
    width:100%;
    height: 590px;
}
.mainScreens .row .img75{
    width: 895px;
    height: 590px;
}
.mainScreens .row .img75 img{
    width:100%;
    height: 590px;
}
.mainScreens .row .smallImg{
    width: 285px;
    height: 590px;
    display: flex;
}
.mainScreens .row .smallImg img{
    width:100%;
    height: 285px;
}
.mainScreens .row .smallImg.top{

    align-items: flex-start;

}
.mainScreens .row .smallImg.bottom{
    align-items: flex-end;
}

.mainScreens .row .longLowImg img{
    width:100%;
    height: 590px;
}
.mainScreens .row .longLowImg{
    height: 590px;
}

.mainScreens.gremm .longLowImg,
.mainScreens.gremm .longLowImg img{
    height: 636px;
}
.mainScreens .row .leftTopRightBottomImgs{
    display: flex;
    justify-content: space-between;
    width: 590px;
    height: 590px;
}
.mainScreens .row .leftTopRightBottomImgs img{
    width: calc(50% - 10px);
    height: 285px;
}
.mainScreens .row .leftTopRightBottomImgs img:last-child{
    margin-top: auto;
}

.mainScreens .row .leftBottomRightTopImgs{
    display: flex;
    justify-content: space-between;
    width: 590px;
    height: 590px;
}
.mainScreens .row .leftBottomRightTopImgs img{
    width: 50%;
    height: 295px;
}
.mainScreens .row .leftBottomRightTopImgs img:first-child{
    margin-top: auto;
}

.mainScreens .row .leftTopRightBottomBigImgs{
    display: flex;
    justify-content: space-between;
    width: 590px;
    height: 590px;
}
.mainScreens .row .leftTopRightBottomBigImgs img{
    width: 50%;
    height: 295px;
}
.mainScreens .row .leftTopRightBottomBigImgs img:last-child{
    margin-top: auto;
}

.mainScreens .row .mediumShortLongImgs{
    display: flex;
    justify-content: space-between;
    width: 590px;
    height: 434px;
    padding-top: 156px;
}
.mainScreens .row .mediumShortLongImgs.topShortLongImgs{
    padding-top: 0;
}
.mainScreens .row .mediumShortLongImgs img{
    width: calc(50% - 10px);
}
.mainScreens .row .mediumShortLongImgs img:first-child{
    height: fit-content;
}
.mainScreens.swift .row .mediumShortLongImgs:nth-child(5) img:first-child{
    height: 285px;
}
.mainScreens.swift .row .mediumShortLongImgs:nth-child(5) img:last-child{
    height: 434px;
}
.mainScreens.swift .row .mediumShortLongImgs:nth-child(7) img:first-child{
    height: 350px;
}
.mainScreens.swift .row .mediumShortLongImgs:nth-child(7) img:last-child{
    height: 450px;
}
.mainScreens.swift .row .mediumShortLongImgs:nth-child(11) img:first-child{
    height: 500px;
}
.mainScreens .row .mediumShortLongImgs img:last-child{
    height: 350px;
}
.mainScreens .row .textBlock{
    width: 590px;
    height: 590px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mainScreens .row .textBlock.textBlockRight{
    align-items: end;
}
.mainScreens .row .textBlock.textBlockRight p, .mainScreens .row .textBlock.textBlockRight label{
    width: 500px;
}
.mainScreens .row .textBlock p{
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0;
    text-align: left;
    width: 407px;
    white-space: pre-line;
}
.mainScreens .row .textBlock label{
    margin-bottom: 19px;
}
.mainScreens .row .smallMediumRow{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 590px;
}
.mainScreens .row .smallMediumRow img:first-child{
    width: 285px;
    height: 285px;
}
.mainScreens .row .smallMediumRow img:last-child{
    width: 765px;
    height: 590px;
}
.projectPage .mainScreens.swift:nth-child(6) .row .textBlock p{
    width: 500px;
}

.projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg,
.projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg img{
  height: 641px;
}

@media (max-width: 1199px) {

    .mainScreens{
        margin-top: 146px;
        margin-bottom: 132px;
    }
    .mainScreens label{
        margin-bottom: 27px;
    }
    .mainScreens p{
        margin-bottom: 51px;
    }
    .mainScreens .row .largeImg{
        height: 900px;
    }
    .mainScreens .row .mediumImg{
        height: 445px;
        width: calc(50% - 5px);
    }
    .mainScreens.swift .row .mediumImg{
        margin-left: auto;
    }
    .mainScreens .row .leftTopRightBottomBigImgs{
        width: 445px;
        height: 445px;
    }
    .mainScreens .row .leftTopRightBottomBigImgs img{
        width: 222.5px;
        height: 222.5px;
    }
    .mainScreens .row{
        row-gap: 10px;
        column-gap: 10px;
    }
    .mainScreens .row .longLowImg img{
        height: 590px;
        object-fit: cover;
    }
    .mainScreens .row .textBlock{
        width: 445px;
        height: 445px;
    }
    .mainScreens h3{
        margin-bottom: 41px;
    }
    .mainScreens .row .longLowImg{
        height: 450px;
        object-fit: cover;
    }
    .mainScreens .row .leftBottomRightTopImgs{
        width: 445px;
        height: 445px;
    }
    .mainScreens .row .leftBottomRightTopImgs img{
        height: 222.5px;
    }
    .mainScreens .row .longLowImg img{
        height: 450px;
    }
    .mainScreens.gremm .longLowImg,
    .mainScreens.gremm .longLowImg img{
        height: 590px;
    }
    .mainScreens .row .largeImg img{
        height: 900px;
    }

    .mainScreens.gremm:nth-child(10) .largeImg,
    .mainScreens.gremm:nth-child(10) .largeImg img{
        height: 671px;
    }
    .mainScreens .row .mediumImg img{
        height: 445px;
    }
    .mainScreens,
    .mainScreens.gremm{
        padding-bottom: 0;
    }
    .mainScreens .row .textBlock.textBlockRight p, .mainScreens .row .textBlock.textBlockRight label{
        width: 405px;

    }
    .projectPage .mainScreens.swift:nth-child(6) .row .textBlock p{
        width: 410px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9) img{
        height: 975px;
    }

    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15) img{
        height: 975px;
    }
    .mainScreens .row .textBlock p{
        margin-bottom: 0;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .mediumImg:nth-child(6){
        display: none;
    }
    .projectPage .mainScreens.vita:nth-child(5) .longLowImg, .projectPage .mainScreens.vita:nth-child(5) .longLowImg img{
        height: 590px;
    }
    .projectPage .mainScreens.vita:nth-child(6) .largeImg, .projectPage .mainScreens.vita:nth-child(6) .largeImg img{
        height: 1000px;
    }
    .projectPage .mainScreens.gremm:nth-child(6) .largeImg, .projectPage .mainScreens.vita:nth-child(6) .largeImg img{
        height: 900px;
    }
    .projectPage .mainScreens.swift:nth-child(7) .img75, .projectPage .mainScreens.swift:nth-child(7) .img75 img{
        height: 445px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9) img{
        /*height: 650px;*/
        height: 975px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15) img{
        /*height: 650px;*/
        height: 975px;
    }
    .projectPage .mainScreens.swift:nth-child(7) .img75, .projectPage .mainScreens.swift:nth-child(7) .img75 img{
        /*height: 300px;*/
        height: 445px;
    }
    .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(5), .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(5) img{
        /*height: 300px;*/
    }
    .projectPage .mainScreens.dsp:nth-child(5) .longLowImg, .projectPage .mainScreens.dsp:nth-child(5) .longLowImg img{
        height: 590px;
    }

    .projectPage .mainScreens.vitacarta:nth-child(6) .row .largeImg,
    .projectPage .mainScreens.vitacarta:nth-child(6) .row .largeImg img{
        height: 1000px;
    }

    .projectPage .mainScreens .longLowImgH{
      width: 100%;
      height: 590px;
      object-fit: cover;
    }
    .projectPage .mainScreens .longLowImgH img{
      width: 100%;
      height: 590px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg,
    .projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg img{
      height: 590px;
    }
}
@media (max-width: 899px) {
    .mainScreens label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 13px;
    }
    .mainScreens p{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 45px;

    }
    .mainScreens .row .largeImg, .mainScreens .row .largeImg img{
        height: 650px;
        width: 100%;
        object-fit: cover;
    }
    .mainScreens.gremm:nth-child(7) .largeImg,
    .mainScreens.gremm:nth-child(7) .largeImg img{
        height: 600px;
        width: 100%;
        object-fit: cover;
    }
    .mainScreens.gremm:nth-child(8) .largeImg,
    .mainScreens.gremm:nth-child(8) .largeImg img{
        height: 600px;
        width: 100%;
        object-fit: cover;
    }

    .mainScreens.gremm:nth-child(10) .largeImg,
    .mainScreens.gremm:nth-child(10) .largeImg img{
        height: 494px;
    }
    .mainScreens.swift .row .mediumImg{
        height: 295px;
    }
    .mainScreens .row .mediumImg img{
        height: 295px;
        object-fit: cover;
    }
    .mainScreens{
        margin-bottom: 93px;
        margin-top: 93px;
    }
    .mainScreens h3{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 31px;
    }
    .mainScreens .row .textBlock{
        width: 295px;
        height: 340px;
    }
    .mainScreens .row .textBlock label{
        margin-bottom: 12px;
    }
    .mainScreens .row .textBlock p{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        width: 275px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg, .projectPage .mainScreens.swift:nth-child(6) .mediumImg img{
        width: 295px;
        height: 340px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .row .textBlock p{
        width: 275px;
    }
    .mainScreens .row .textBlock.textBlockRight p, .mainScreens .row .textBlock.textBlockRight label{
        width: 275px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(8){
        margin-left: 0;
    }
    .mainScreens .row .img75, .mainScreens .row .img75 img{
        height: 300px;
        object-fit: cover;
    }
    .mainScreens.vita .row .mediumImg, .mainScreens.vita .row .mediumImg img{
        height: 340px;
    }
    .mainScreens.megacvet .row .mediumImg, .mainScreens.megacvet .row .mediumImg img{
        height: 370px;
    }
    .projectPage .mainScreens.vita:nth-child(6) .row .mediumImg, .mainScreens.vita:nth-child(6) .row .mediumImg img{
        height: 340px;
    }
    .projectPage .mainScreens.megacvet:nth-child(6) .row .mediumImg, .mainScreens.megacvet:nth-child(6) .row .mediumImg img{
        height: 370px;
    }
    .mainScreens .row .longLowImg, .mainScreens .row .longLowImg img{
        height: 295px;
    }
    .mainScreens.gremm:nth-child(7) .longLowImg,
    .mainScreens.gremm:nth-child(7) .longLowImg img{
        height: 370px;
    }
    .mainScreens.gremm:nth-child(9) .longLowImg,
    .mainScreens.gremm:nth-child(9) .longLowImg img{
        height: 370px;
    }
    .projectPage .mainScreens.vita:nth-child(7) .row .mediumImg, .projectPage .mainScreens.vita:nth-child(7) .row .mediumImg img{
        width: 280px;
        height: 280px;
    }
    .projectPage .mainScreens.gremm:nth-child(7) .row .mediumImg,
    .projectPage .mainScreens.gremm:nth-child(7) .row .mediumImg img{
        width: 295px;
        height: 370px;
    }
    .mainScreens .row .leftBottomRightTopImgs{
        width: 280px;
        height: 280px;
    }
    .mainScreens .row .leftBottomRightTopImgs img{
        height: 140px;
    }
    .projectPage .mainScreens.vita:nth-child(7) .row .leftBottomRightTopImgs{
        margin-left: auto;
    }
    .projectPage .mainScreens.delay:nth-child(5) .row .mediumImg, .projectPage .mainScreens.delay:nth-child(5) .row .mediumImg img{
        height: 369px;
    }
    .projectPage .mainScreens.delay:nth-child(5) .row .largeImg, .projectPage .mainScreens.delay:nth-child(5) .row .largeImg img{
        height: 514px;
    }
    .projectPage .mainScreens.delay:nth-child(5) .row .longLowImg, .projectPage .mainScreens.delay:nth-child(5) .row .longLowImg img{
        height: 370px;
    }
    .mainScreens .row .leftTopRightBottomBigImgs{
        width: 280px;
        height: 280px;
        margin-left: auto;
    }
    .mainScreens .row .leftTopRightBottomBigImgs img{
        height: 140px;
    }
    .projectPage .mainScreens.delay:nth-child(6) .row .mediumImg, .projectPage .mainScreens.delay:nth-child(6) .row .mediumImg img{
        height: 280px;
        width: 280px;
    }
    .projectPage .mainScreens.dsp:nth-child(5) .row .largeImg, .projectPage .mainScreens.dsp:nth-child(5) .row .largeImg img{
        height: 600px;
    }
    .projectPage .mainScreens.dsp:nth-child(5) .row .mediumImg, .projectPage .mainScreens.dsp:nth-child(5) .row .mediumImg img{
        height: 295px;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .row .mediumImg, .projectPage .mainScreens.dsp:nth-child(6) .row .mediumImg img{
        height: 295px;
    }
    .projectPage .mainScreens.dsp:nth-child(5) .row .longLowImg, .projectPage .mainScreens.dsp:nth-child(5) .row .longLowImg img{
        height: 300px;
    }

    .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs img:first-child{
        display: none;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs, .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs img:last-child{
        width: 295px;
        height: 295px;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .row .largeImg, .projectPage .mainScreens.canadian:nth-child(5) .row .largeImg img{
        height: 600px;
    }

    .projectPage .mainScreens.canadian:nth-child(5) .row .mediumImg, .projectPage .mainScreens.canadian:nth-child(5) .row .mediumImg img{
        height: 295px;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .row .longLowImg, .projectPage .mainScreens.canadian:nth-child(5) .row .longLowImg img{
        height: 300px;
    }

    .projectPage .mainScreens.canadian:nth-child(6) .row .mediumImg, .projectPage .mainScreens.canadian:nth-child(6) .row .mediumImg img{
        height: 280px;
    }

    .projectPage .mainScreens.canadian:nth-child(6) .row .leftBottomRightTopImgs{
        margin-left: auto;
    }

    .projectPage .mainScreens.vita:nth-child(5) .row .largeImg,
    .projectPage .mainScreens.vita:nth-child(5) .row .largeImg img{
        height: 514px;
    }
    .projectPage .mainScreens.megacvet:nth-child(5) .row .largeImg,
    .projectPage .mainScreens.megacvet:nth-child(5) .row .largeImg img{
      height: 600px;
    }
    .projectPage .mainScreens.swift:nth-child(5) .row .largeImg, .projectPage .mainScreens.swift:nth-child(5) .row .largeImg img{
        height: 514px;
    }

    .projectPage .mainScreens.vita:nth-child(5) .row .longLowImg, .projectPage .mainScreens.vita:nth-child(5) .row .longLowImg img{
        height: 370px;
    }

    .projectPage .mainScreens.delay:nth-child(6) .row .leftTopRightBottomBigImgs img:first-child{
        margin-top: auto;
    }
    .projectPage .mainScreens.delay:nth-child(6) .row .leftTopRightBottomBigImgs img:last-child{
        margin-top: 0;
    }
    .projectPage .mainScreens.vita:nth-child(6) .largeImg, .projectPage .mainScreens.vita:nth-child(6) .largeImg img{
        height: 650px;
    }
    .projectPage .mainScreens.gremm:nth-child(6) .largeImg, .projectPage .mainScreens.vita:nth-child(6) .largeImg img{
        height: 600px;
    }
    .mainScreens .row .longLowImgH,
    .mainScreens .row .longLowImgH img{
        height: 370px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(5) .row .largeImg,
    .projectPage .mainScreens.vitacarta:nth-child(5) .row .largeImg img{
        height: 514px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(5) .row .mediumImg,
    .projectPage .mainScreens.vitacarta:nth-child(5) .row .mediumImg img{
        height: 370px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(6) .row .largeImg,
    .projectPage .mainScreens.vitacarta:nth-child(6) .row .largeImg img{
        height: 650px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .mediumImg,
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .mediumImg img{
        height: 295px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .leftBottomRightTopImgs{
        width: 295px;
        height: 295px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg,
    .projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg img{
        height: 370px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9) img{
        height: 650px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15) img{
        height: 650px;
    }
    .projectPage .mainScreens.swift:nth-child(7) .img75, .projectPage .mainScreens.swift:nth-child(7) .img75 img{
        height: 300px;
    }
}

@media (max-width: 599px) {
    .mainScreens{
        margin-top: 93px;
        margin-bottom: 93px;
    }
    .mainScreens p{
        margin-bottom: 35px;
    }
    .mainScreens .row .mediumImg{
        width: 100%;
    }
    .mainScreens.vita .row .mediumImg, .mainScreens.vita .row .mediumImg img{
        height: 369px;
    }
    .mainScreens.gremm:nth-child(5) .row .mediumImg:nth-last-child(-n+2),
    .mainScreens.gremm:nth-child(5) .row .mediumImg:nth-last-child(-n+2) img{
        height: 300px;
    }
    .projectPage .mainScreens.vita:nth-child(6) .largeImg, .projectPage .mainScreens.vita:nth-child(6) .largeImg img{
        height: 1545px;
    }
    .projectPage .mainScreens.gremm:nth-child(6) .largeImg, .projectPage .mainScreens.vita:nth-child(6) .largeImg img{
        height: 809px;
    }
    .projectPage .mainScreens.vita:nth-child(5) .row .longLowImg, .projectPage .mainScreens.vita:nth-child(5) .row .longLowImg img{
        height: 949px;
    }
    .projectPage .mainScreens.gremm:nth-child(5) .row .longLowImg,
    .projectPage .mainScreens.gremm:nth-child(5) .row .longLowImg img{
        height: 580px;
    }
    .projectPage .mainScreens.megacvet:nth-child(5) .row .largeImg,
    .projectPage .mainScreens.megacvet:nth-child(5) .row .largeImg img{
        height: 900px;
    }
    .projectPage .mainScreens.megacvet:nth-child(5) .row .largeImg:last-child,
    .projectPage .mainScreens.megacvet:nth-child(5) .row .largeImg:last-child img{
        height: 1747px;
    }
    .projectPage .mainScreens.gremm:nth-child(5) .row .largeImg,
    .projectPage .mainScreens.gremm:nth-child(5) .row .largeImg img{
        height: 1086px;
    }
    .projectPage .mainScreens.gremm:nth-child(5) .row .largeImg:last-child,
    .projectPage .mainScreens.gremm:nth-child(5) .row .largeImg:last-child img{
        height: 1086px;
    }

    .mainScreens .row .textBlock{
        height: auto;
        width: 300px;
    }
    .mainScreens .row .textBlock p{
        width: 300px;
    }
    .projectPage .mainScreens.vita:nth-child(6) .row .mediumImg, .mainScreens.vita:nth-child(6) .row .mediumImg img{
        width: 300px;
    }
    .projectPage .mainScreens.vita:nth-child(6) .row .longLowImg, .projectPage .mainScreens.vita:nth-child(6) .row .longLowImg img{
        height: 1545px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .row .textBlock p{
        width: 300px;
    }
    .projectPage .mainScreens.vita:nth-child(7) .row .longLowImg, .projectPage .mainScreens.vita:nth-child(7) .row .longLowImg img{
        height: 600px;
    }
    .projectPage .mainScreens.gremm:nth-child(7) .row .longLowImg,
    .projectPage .mainScreens.gremm:nth-child(7) .row .longLowImg img{
        height: 580px;
    }
    .projectPage .mainScreens.vita:nth-child(7) .row .mediumImg, .projectPage .mainScreens.vita:nth-child(7) .row .mediumImg img{
        width: 300px;
        height: 300px;
    }
    .projectPage .mainScreens.gremm:nth-child(7) .row .mediumImg:nth-child(2),
    .projectPage .mainScreens.gremm:nth-child(7) .row .mediumImg:nth-child(2) img{
        width: 300px;
        height: 369px;
    }
    .projectPage .mainScreens.vita:nth-child(7) .row .leftBottomRightTopImgs{
        width: 300px;
        height: 300px;
    }
    .mainScreens .row .leftBottomRightTopImgs img{
        width: 150px;
        height: 150px;
    }
    .projectPage .mainScreens.swift:nth-child(5) .row .largeImg, .projectPage .mainScreens.swift:nth-child(5) .row .largeImg img{
        height: 350px;
    }
    .mainScreens.swift .row .mediumImg, .mainScreens.swift .row .mediumImg img{
        height: 350px;
    }
    .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(3), .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(3) img{
        width: 250px;
        height: 250px;
        margin-left: 0;
    }
    .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(6), .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(6) img{
        width: 250px;
        height: 250px;
        margin-left: 0;
    }
    .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(4), .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(4) img{
        width: 250px;
        height: 250px;
    }

    .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(7), .projectPage .mainScreens.swift:nth-child(5) .row .mediumImg:nth-child(7) img{
        width: 250px;
        height: 300px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(2), .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(2) img{
        height: 340px;
        width: 300px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(3), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(3) img{
        height: 300px;
        width: 300px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(7), .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(7) img{
        height: 250px;
        width: 250px;
        margin-left: 0;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(8), .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(8) img{
        height: 300px;
        width: 250px;
        margin-left: auto;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9) img{
        height: 340px;
        width: 300px;
        margin-left: auto;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg{
        width: 300px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(12), .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(12) img{
        height: 250px;
        width: 250px;
        margin-left: 0;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(13), .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(13) img{
        height: 300px;
        width: 250px;
        margin-left: auto;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15), .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15) img{
        height: 340px;
        width: 300px;
    }

    .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(1), .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(1) img{
        height: 300px;
        width: 300px;
    }
    .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(2), .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(2) img{
        height: 250px;
        width: 250px;
        margin-left: 0;
    }
    .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(3), .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(3) img{
        height: 350px;
        width: 250px;
        margin-left: auto;
    }
    .projectPage .mainScreens.swift:nth-child(7) .img75:nth-child(4), .projectPage .mainScreens.swift:nth-child(7) .img75:nth-child(4) img{
        height: 200px;
        width: 300px;
    }
    .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(5), .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(5) img{
        height: 250px;
        width: 250px;
        margin-left: 0;
    }
    .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(6), .projectPage .mainScreens.swift:nth-child(7) .mediumImg:nth-child(6) img{
        height: 350px;
        width: 250px;
        margin-left: auto;
    }

    .projectPage .mainScreens.swift:nth-child(7) .img75:nth-child(7), .projectPage .mainScreens.swift:nth-child(7) .img75:nth-child(7) img{
        height: 200px;
        width: 300px;
    }
    .projectPage .mainScreens.delay:nth-child(5) .row .longLowImg, .projectPage .mainScreens.delay:nth-child(5) .row .longLowImg img{
        height: 949px;
    }

    .projectPage .mainScreens.delay:nth-child(6) .row .longLowImg, .projectPage .mainScreens.delay:nth-child(6) .row .longLowImg img{
        height: 600px;
    }
    .projectPage .mainScreens.delay:nth-child(6) .row .mediumImg, .projectPage .mainScreens.delay:nth-child(6) .row .mediumImg img{
        width: 300px;
        height: 300px;
    }
    .mainScreens.delay .row .leftTopRightBottomBigImgs{
        width: 300px;
        height: 300px;
    }
    .mainScreens.delay .row .leftTopRightBottomBigImgs img{
        width: 150px;
        height: 150px;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .row .largeImg, .projectPage .mainScreens.canadian:nth-child(5) .row .largeImg img{
        height: 350px;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .row .mediumImg, .projectPage .mainScreens.canadian:nth-child(5) .row .mediumImg img{
        height: 300px;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .row .longLowImg, .projectPage .mainScreens.canadian:nth-child(5) .row .longLowImg img{
        height: 529px;
    }
    .projectPage .mainScreens.canadian:nth-child(6) .row .leftBottomRightTopImgs{
        width: 300px;
        height: 300px;
    }
    .projectPage .mainScreens.dsp:nth-child(5) .row .largeImg, .projectPage .mainScreens.dsp:nth-child(5) .row .largeImg img{
        height: 350px;
    }
    .projectPage .mainScreens.dsp:nth-child(5) .row .mediumImg, .projectPage .mainScreens.dsp:nth-child(5) .row .mediumImg img{
        height: 300px;
    }
    .projectPage .mainScreens.dsp:nth-child(5) .row .longLowImg, .projectPage .mainScreens.dsp:nth-child(5) .row .longLowImg img{
        height: 986px;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .row .mediumImg, .projectPage .mainScreens.dsp:nth-child(6) .row .mediumImg img{
        height: 300px;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs, .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs img:last-child{
        height: 300px;
        width: 300px;
    }
    .projectPage .mainScreens.canadian:nth-child(5) .row .longLowImg:last-child, .projectPage .mainScreens.canadian:nth-child(5) .row .longLowImg:last-child img{
        height: 986px;
    }
    .mainScreens .row .textBlock.textBlockRight{
        align-items: start;
        margin-top: 58px;

    }
    .mainScreens .row .textBlock{
        margin-bottom: 35px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .textBlock:nth-child(1){
        order: 1;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(2){
        order: 2;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(3){
        order: 3;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(4){
        order: 5;
    }
    .projectPage .mainScreens.swift:nth-child(6) .textBlock:nth-child(5){
        order: 4;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(6){
        order: 6;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(7){
        order: 7;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(8){
        order: 8;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(9){
        order: 9;
    }
    .projectPage .mainScreens.swift:nth-child(6) .textBlock:nth-child(10){
        order: 10;
        margin-top: 58px;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(11){
        order: 11;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(12){
        order: 12;
    }
    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(13){
        order: 13;
    }

    .projectPage .mainScreens.swift:nth-child(6) .mediumImg:nth-child(14){
        order: 14;
    }
    .projectPage .mainScreens.swift:nth-child(6) .largeImg:nth-child(15){
        order: 15;
    }

    .projectPage .mainScreens.dsp:nth-child(6) .mediumImg:nth-child(3), .projectPage .mainScreens.dsp:nth-child(6) .mediumImg:nth-child(3) img{
        height: 150px;
        width: 150px;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .mediumImg:nth-child(3) img{
        margin-top: 150px;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs, .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs img:last-child{
        height: 150px;
        width: 150px;
    }
    .projectPage .mainScreens.dsp:nth-child(6) .row .leftBottomRightTopImgs img:last-child{
        margin-left: 150px;
        margin-top: -160px;
    }

    .projectPage .mainScreens.vitacarta:nth-child(5) .row .largeImg,
    .projectPage .mainScreens.vitacarta:nth-child(5) .row .largeImg img{
        width: 100%;
        height: 745px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(6) .row .largeImg,
    .projectPage .mainScreens.vitacarta:nth-child(6) .row .largeImg img{
        height: 1210px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .longLowImg,
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .longLowImg img{
        height: 600px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .mediumImg,
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .mediumImg img{
        height: 300px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(8) .row .leftBottomRightTopImgs{
        height: 300px;
    }
    .projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg,
    .projectPage .mainScreens.vitacarta:nth-child(7) .row .longLowImg img{
        height: 920px;
    }

    .mainScreens .row .longLowImgH,
    .mainScreens .row .longLowImgH img{
        height: 949px;
    }

    .projectPage .mainScreens.gremm .longLowImgH,
    .projectPage .mainScreens.gremm .longLowImgH img{
        height: 580px;
    }

    .mainScreens .row .longLowImgW,
    .mainScreens .row .longLowImgW img{
        width: 100%;
        height: 920px;
    }

    .mainScreens.gremm:nth-child(7) .largeImg,
    .mainScreens.gremm:nth-child(7) .largeImg img{
        height: 1086px;
        width: 100%;
        object-fit: cover;
    }

    .mainScreens.gremm:nth-child(8) .largeImg,
    .mainScreens.gremm:nth-child(8) .largeImg img{
        height: 1062px;
        width: 100%;
        object-fit: cover;
    }

    .mainScreens.gremm:nth-child(9) .longLowImg,
    .mainScreens.gremm:nth-child(9) .longLowImg img{
        height: 833px;
    }

    .mainScreens.gremm:nth-child(10) .largeImg,
    .mainScreens.gremm:nth-child(10) .largeImg img{
        height: 1400px;
    }
}