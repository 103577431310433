.runningPageIcons{
    background-color: black;

    height: 600px;
    overflow: hidden;
}
.runningPageIcons.padding{
    padding-top: 200px;
    height: 400px;
}
.runningPageIcons .greyLine{
    background-color: rgba(255, 255, 255, 0.3);
    height: 1px;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;

}
@media (max-width: 1199px) {
    .runningPageIcons{
        height: 550px;
    }
    .runningPageIcons.padding{
        padding-top: 150px;
        height: 350px;
    }
}
@media (max-width: 899px) {
    .runningPageIcons{
        height: 328px;
    }
    .runningPageIcons.padding{
        padding-top: 100px;
        height: 228px;
    }


}
@media (max-width: 599px) {
    .runningPageIcons{
        height: 307px;
    }
}