.aboutUsPage{
    padding-top: 215px;
}
.aboutUsPage .heading h2{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;

}
.parallaxBlock{
    height: 800px;
    overflow: hidden;


}
.parallaxBlock img{
    width: 100%;
    object-fit: cover;
}
.aboutUsPage > .parallaxBlock > img{
    width: 100%;
    height: 900px;
    object-fit: cover;
}
.aboutUsPage .heading{
    width: 100%;
    justify-content: space-between;
    margin-bottom: 81px;
}
.aboutUsPage .heading p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 33px;
    width: 389px;
}
.aboutUsPage .firstBlock{
    margin-top: 190px;
    margin-bottom: 150px;
}
.aboutUsPage .firstBlock > p{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 87px;
}
.aboutUsPage .firstBlock label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    display: block;
    color: #00000080;
    text-transform: uppercase;
}
.aboutUsPage .firstBlock > .row{
    justify-content: space-between;
}
.aboutUsPage .firstBlock .text{
    width: 710px;
}
.aboutUsPage .firstBlock .smallDropdownBlock{
    width: 390px;
}
.aboutUsPage .firstBlock .text label{
    margin-bottom: 31px;
}
.aboutUsPage .firstBlock .smallDropdownBlock label{
    margin-bottom: 35px;
}
.aboutUsPage .firstBlock .text p{
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 35px;
}
.aboutUsPage .firstBlock .text .number:first-child{
    margin-right: 100px;
}
.aboutUsPage .firstBlock .text .number h3{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;

}
.aboutUsPage .firstBlock .text .number h3 span{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;

}
.aboutUsPage .firstBlock .text .number p{
    margin-top: -3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}
.aboutUsPage .secondBlock{
    margin-top: 196px;
}
.aboutUsPage .secondBlock label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #00000080;

}
.aboutUsPage .thirdBlock{
    margin-top: 196px;
    margin-bottom: 200px;
}
.aboutUsPage .thirdBlock > label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000080;
    text-transform: uppercase;
    margin-bottom: 38px;
    display: block;
}
.aboutUsPage .thirdBlock > p{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 91px;
}
.aboutUsPage .thirdBlock > .row{
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 40px;
}

.aboutUsPage .thirdBlock .imgCard{
    background-color: #EAEDF0;
    width: 373px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.aboutUsPage .fifthBlock{
    background-color: #EAEDF0;
    padding-top: 200px;
    padding-bottom: 200px;
}
.aboutUsPage .fifthBlock img{
    width: 550px;
    height: 800px;
}
.aboutUsPage .fifthBlock .row{
    column-gap: 100px;
}
.aboutUsPage .fifthBlock .text{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.aboutUsPage .fifthBlock .text h3{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 55px;
}
.aboutUsPage .fifthBlock .text p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px;
}
.aboutUsPage .fifthBlock .text a{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 5px;
    border-bottom: 1px solid #00000033;
    width: min-content;
    transition-duration: 300ms;

}
.aboutUsPage .fifthBlock .text a:hover{
    color: #388E3C;
}
.aboutUsPage .fifthBlock .text .animatedLink .bottomLine{
    margin-bottom: -6px;
    margin-top: 5px;
}

@media (max-width: 1199px) {
    .aboutUsPage .fifthBlock .row{
        column-gap: 50px;
    }
    .parallaxBlock{
        height: 600px;
    }
    .aboutUsPage{
        padding-top: 170px;
    }
    .aboutUsPage .heading{
        margin-bottom: 61px;
    }
    .aboutUsPage .firstBlock{
        margin-top: 140px;
        margin-bottom: 75px;
    }
    .aboutUsPage .firstBlock > p{
        margin-bottom: 47px;
    }
    .aboutUsPage .firstBlock .text{
        width: 450px;
    }
    .aboutUsPage .secondBlock{
        margin-top: 140px;
    }
    .aboutUsPage .thirdBlock{
        margin-top: 146px;
        margin-bottom: 150px;
    }
    .aboutUsPage .thirdBlock .imgCard{
        width: 430px;
        height: 230px;
    }
    .aboutUsPage .fifthBlock{
        padding-top: 150px;
    }
    .aboutUsPage .fifthBlock img{
        width: 450px;
        height: 650px;
    }
    .aboutUsPage .fifthBlock .text h3{
        width: 400px;
        font-size: 80px;
        font-weight: 400;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 44px;
    }
}
@media (max-width: 899px) {
    .parallaxBlock{
        height: 350px;
    }
    .aboutUsPage{
        padding-top: 110px;
    }
    .aboutUsPage .heading h2{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }
    .aboutUsPage .heading p{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 0;
        width: 282px;
    }
    .aboutUsPage .heading{
        margin-bottom: 45px;
    }
    .aboutUsPage > .parallaxBlock > img{
        height: 450px;
    }
    .aboutUsPage .firstBlock{
        margin-top: 96px;
    }
    .aboutUsPage .firstBlock > p{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }
    .aboutUsPage .firstBlock .text{
        width: 280px;
    }
    .aboutUsPage .firstBlock .smallDropdownBlock{
        width: 280px;
    }
    .aboutUsPage .firstBlock label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

    }
    .aboutUsPage .firstBlock .text label{
        margin-bottom: 22px;
    }
    .aboutUsPage .firstBlock .text p{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 19px;

    }
    .aboutUsPage .firstBlock .text .number:first-child{
        margin-right: 80px;
    }
    .aboutUsPage .firstBlock .text .number h3{
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
    }
    .aboutUsPage .firstBlock .text .number h3 span{
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
    .aboutUsPage .firstBlock .text .number p{
        margin-top: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

    }
    .aboutUsPage .firstBlock .smallDropdownBlock label{
        margin-bottom: 27px;
    }
    .aboutUsPage .secondBlock{
        margin-top: 98px;
    }
    .aboutUsPage .secondBlock label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

    }
    .aboutUsPage .thirdBlock{
        margin-top: 98px;
        margin-bottom: 100px;
    }
    .aboutUsPage .thirdBlock > label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 23px;
    }
    .aboutUsPage .thirdBlock > p{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 45px;
    }
    .aboutUsPage .thirdBlock .imgCard{
        width: 280px;
        height: 150px;
    }
    .aboutUsPage .thirdBlock .imgCard:first-child img, .aboutUsPage .thirdBlock .imgCard:nth-child(5) img{
        height: 60px;

    }
    .aboutUsPage .thirdBlock .imgCard:nth-child(2) img, .aboutUsPage .thirdBlock .imgCard:nth-child(3) img, .aboutUsPage .thirdBlock .imgCard:nth-child(4) img, .aboutUsPage .thirdBlock .imgCard:nth-child(6) img{
        height: 40px;

    }
    .aboutUsPage .fifthBlock{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .aboutUsPage .fifthBlock img{
        width: 280px;
        height: 400px;
    }
    .aboutUsPage .fifthBlock .text h3{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 28px;
        width: 280px;
    }
    .aboutUsPage .fifthBlock .text p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 23px;
        width: 280px;
    }
    .aboutUsPage .fifthBlock .row{
        column-gap: 40px;
    }
    .aboutUsPage .fifthBlock .text a{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

    }
}
@media (max-width: 599px) {
    .aboutUsPage{
        padding-top: 97px;
    }
    .aboutUsPage .heading{
        flex-direction: column;
        margin-bottom: 55px;
    }
    .aboutUsPage .heading h2{
        margin-bottom: 18px;
    }
    .parallaxBlock{
        height: 320px;
    }
    .aboutUsPage > .parallaxBlock > img{
        height: 420px;
    }
    .aboutUsPage .firstBlock{
        margin-top: 78px;
        margin-bottom: 60px;
    }
    .aboutUsPage .firstBlock > p{
        margin-bottom: 43px;
    }
    .aboutUsPage .firstBlock > .row{
        flex-direction: column;
    }
    .aboutUsPage .firstBlock .text .row{
        width: 245px;
        margin-bottom: 43px;
    }
    .aboutUsPage .secondBlock{
        margin-top: 78px;
    }
    .aboutUsPage .secondBlock label{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: right;
        text-transform: none;
    }

    .aboutUsPage .thirdBlock{
        margin-top: 75px;
        margin-bottom: 80px;
    }
    .aboutUsPage .thirdBlock > p{
        margin-bottom: 35px;
    }
    .aboutUsPage .thirdBlock > .row{
        column-gap: 0;
        row-gap: 10px;
    }
    .aboutUsPage .thirdBlock .imgCard{
        width: 300px;
    }
    .aboutUsPage .fifthBlock{
        padding-top: 68px;
        padding-bottom: 0;
    }
    .aboutUsPage .fifthBlock .row{
        flex-direction: column-reverse;
    }
    .aboutUsPage .fifthBlock img{
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-top: 60px;
    }
    .aboutUsPage .fifthBlock .text p{
        width: 300px;
    }
}