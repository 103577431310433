.footer{
    background-color: black;
    padding-bottom: 100px;
    padding-top: 100px;
    height: 384px;
}

.footer .content{
    justify-content: space-between;
    align-items: flex-start;
}
.footer .footerLinks{
    margin-left: 124px;
}
.footer .footerLinks a{
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-bottom: 20px;
    padding-bottom: 4px;
    transition-duration: 300ms;
    width: max-content;

}
.footer .footerLinks a:last-child{
    margin-bottom: 0;
}
.footer .footerLinks a:hover{
    border-color: white;
}
.footer .downloadFile{
    display: flex;
}
.footer .downloadFile .arrowCircle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 300ms;
    margin-right: 15px;
}
.footer .downloadFile:hover .arrowCircle{
    background-color: #388E3C;
    border-color: #388E3C;
}
footer .downloadFile .text{

    margin-top: 2px;
}
.footer .downloadFile .text p{
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-bottom: 10px;
}
.footer .downloadFile .text label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.6;
}
.footer .greyLine{
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 100px;
}
.footer .row:last-child p{
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.6
}
.footer .row:last-child{
    justify-content: space-between;
    margin-top: 98px;
}
.footer .row:last-child a{
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    padding-bottom: 4px;
    transition-duration: 300ms;
    width: max-content;

}

.footer .row:last-child a:hover{
    border-color: white;
}

@media (max-width: 1199px) {
    .footer{
        height: 257px;
    }
    .footer .row:last-child{
        margin-top: 40px;
    }
    .footer{
        padding-bottom: 45px;
        padding-top: 50px;
    }
    .footer .greyLine{
        margin-top: 55px;
    }
    .footer .footerLinks a{
        margin-bottom: 12px;
    }
}
@media (max-width: 899px){
    .footer{
        padding-top: 60px;
        padding-bottom: 40px;
        height: 322px;
    }
    .footer .content img{
        height: 20px;
        width: 102px;

    }
    .footerLinks{
        display: none;
    }
    .footer .row:last-child .animatedLink{
        display: none;

    }
    .footer .row:last-child{
        justify-content: center;
    }
    .footer .greyLine{
        margin-top: 40px;
    }
    .footer .downloadFile{
        display: none;
    }
    .footer .footerLinksMob .footerLinks{
        display: block;
        margin-left: 0;
    }
    .footer .footerLinksMob .footerLinks a{
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 14px;
    }
    .footer .footerLinksMob .footerLinks a:last-child{
        margin-bottom: 0;
    }
    .footer .footerLinksMob .social .animatedLink{
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        display: block;
        margin-bottom: 18px;

    }
    .footer .footerLinksMob{
        width: 280px;
    }
    .footer .footerLinksMob .row{
        justify-content: space-between;
    }
    .footer .footerLinksMob .social .animatedLink span{
        display: block;
    }
    .footer .footerLinksMob .downloadFile{
        display: flex;
        margin-top: 39px;
    }
    .footer .downloadFile .text p{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .footer .downloadFile .text label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        display: block;
    }
}
@media (max-width: 599px) {
    .footer .content{
        flex-direction: column;
    }
    .footer{
        padding-top: 50px;
        height: 382px;
    }
    .footer .content img{
        margin-bottom: 40px;
    }
    .footer .footerLinksMob{
        width: 208px;
    }
    .footer .content .arrowCircle img{
        margin-bottom: 0;
    }
    .footer .row:last-child{
        justify-content: flex-start;
    }
    .footer .downloadFile .text p{
        width: max-content;
    }
}