.projects{
    margin-bottom: 200px;
}
.projects .headerLine{
    display: flex;
    justify-content: space-between;
    padding-bottom: 31px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.projects .headerLine p{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;

}
.projects .content{
    margin-top: 100px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.projects .content .col:last-child{
    margin-top: 150px;
}
.projects button{
    width: 100%;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    transition-duration: 300ms;
    margin-top: 95px;
}
.projects button:hover{
    color: white;
    border-color: #388E3C;
}

@media (max-width: 1199px) {
    .projects .content{
        margin-top: 60px;
    }
    .projects{
        margin-bottom: 150px;
    }
    .projects button{
        margin-top: 55px;
    }
}
@media (max-width: 899px) {
    .projects button{
        margin-top: 35px;
    }
    .projects .headerLine{
        padding-bottom: 23px;
    }
    .projects .headerLine p{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }
    .projectCard .thumbnail, .projectCard .thumbnail .projectImage{
        width: 280px;
        height: 350px;
    }
    .projects .content{
        margin-top: 40px;
    }
    .projects .content .col:last-child{
        margin-top: 60px;
    }
    .projectCard{
        margin-bottom: 35px;
    }
    .projectCard p{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }
    .projectCard label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

    }
    .projects button{
        height: 50px;
    }
    .projects{
        margin-bottom: 100px;
    }

}
@media (max-width: 599px) {
    .projects .content{
        margin-top: 35px;
        flex-direction: column;
    }
    .projects .content .col:last-child{
        margin-top: 35px;
    }
    .projectCard .thumbnail, .projectCard .thumbnail .projectImage{
        width: 300px;
        height: 350px;
    }
    .projectCard .thumbnail{
        margin-bottom: 17px;
    }
    .projects{
        margin-bottom: 80px;
    }

}