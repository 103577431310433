.reviewsSlider{
    padding-top: 190px;
    padding-bottom: 198px;
    background-color: black;
}
.reviewsSlider h4{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 67px;
    min-height: 288px;
    width: 100%;
    color: white;
}
.reviewsSlider p{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-bottom: 5px;
}
.reviewsSlider label{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.6;
    margin-bottom: 22px;
    display: block;

}
.reviewsSlider span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-left: 20px;
    margin-right: 20px;

}
.reviewsSlider img{
    cursor: pointer;
}

@media (max-width: 1199px) {
    .reviewsSlider{
        padding-bottom: 150px;
    }
    .reviewsSlider h4{
        margin-bottom: 37px;
    }
}
@media (max-width: 899px) {
    .reviewsSlider{
        padding-top: 96px;
        padding-bottom: 100px;
    }
    .reviewsSlider h4{
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        min-height: 144px;
    }
    .reviewsSlider p{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 6px;
    }
    .reviewsSlider label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 33px;
    }
    .reviewsSlider span{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

    }
}
@media (max-width: 599px) {
    .reviewsSlider{
        padding-top: 76px;
        padding-bottom: 84px;
    }
}