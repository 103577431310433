.smallDropdownItem{
    padding-top: 22px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 25px;
    overflow: hidden;
    max-height: 21px;
    min-height: 21px;
    cursor: pointer;
    transition-duration: 300ms;
    height: 1%;
}

.smallDropdownItem:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.smallDropdownItem .row{
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}
.smallDropdownItem span{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

}
.smallDropdownItem p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000099;
    opacity: 0;
    transition-duration: 300ms;
}
.smallDropdownItem .plus{
    position: relative;
    cursor: pointer;
}
.smallDropdownItem .plus .horizPart{
    transition-duration: 300ms;
}
.smallDropdownItem:hover .plus .vertPart, .smallDropdownItem:hover .plus .horizPart{
    background-color: #388E3C;
    margin-right: 10px;
}
.smallDropdownItem .plus .vertPart, .smallDropdownItem .plus .horizPart{
    width: 20px;
    height: 2px;
    background-color: black;
}
.smallDropdownItem .plus .vertPart{
    transform: rotate(90deg);
    position: absolute;
    transition-duration: 300ms;
}
.smallDropdownItem.opened .plus .vertPart{
    transform: rotate(0deg);
}
.smallDropdownItem.opened{
    max-height: 1000px!important;
    height: 100%;
}
.smallDropdownItem.opened p{
    opacity: 1;
}
@media (max-width: 899px) {
    .smallDropdownItem{
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .smallDropdownItem .row{

    }
    .smallDropdownItem span{
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }
    .smallDropdownList .smallDropdownItem:nth-child(2){
        max-height: 38px;
        min-height: 38px;
    }
}
@media (max-width: 599px) {
    .smallDropdownItem p{
        width: 300px;
    }
    .smallDropdownItem{
        width: 300px;
    }
    .smallDropdownList .smallDropdownItem:nth-child(2){
        max-height: 21px;
        min-height: 21px;
    }
}