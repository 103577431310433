.runningPageLine{
    display: flex;
    height: 180px;
    cursor: pointer;
    overflow: hidden;
}
.runningPageLine:hover{
    background-color: #388E3C;
}
.runningPageLine:hover .arrowRight{
    display: block;
}
.runningPageLine:hover .mainImg{
    display: none;
}
.runnerHolder.left:hover{
    transition: linear;
    transform:translateX(11000px);
    transition-duration: 200000ms;
}
.runnerHolder.right:hover{
    transition: linear;
    transform:translateX(-15500px);
    transition-duration: 200000ms;
}
.runnerHolder, .runnerHolder .basic, .runnerHolder .shadow{
    display: flex;
}
.runnerHolder{
    position: relative;
    transition-duration: 400000ms;
}
.runnerHolder .basic.l1624{
    position: absolute;
    animation-duration: 60000ms;
    animation-name: changePos1624;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.runnerHolder .shadow.l1624{
    position: absolute;
    animation-duration: 60000ms;
    animation-name: changePos1624shadow;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.runnerHolder .basic.l2099{
    position: absolute;
    animation-duration: 80000ms;
    animation-name: changePos2099;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.runnerHolder .shadow.l2099{
    position: absolute;
    animation-duration: 80000ms;
    animation-name: changePos2099shadow;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.runningPageLine.margin{
    margin-left: -237px;
}
.runningPageLine .mainImg{
    width: 300px;
    height: 140px;
    border-radius: 70px;
}
.runningPageLine .arrowRight{
    display: none;
    width: 60px;
    height: 60px;
    padding: 40px 124px 40px 116px;
    background-color: #191919;
    border-radius: 70px;
}
.runningPageLine h1{
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    width: max-content;
    margin-right: 50px;
}
.runningPageLine .icon{
    display: flex;
    align-items: center;
    margin-right: 50px;
}
@keyframes changePos1624 {
    from {
        left: -7308px;
    }

    to {
        left: 0px;
    }
}
@keyframes changePos1624shadow {
    from {
        left: 0px;
    }

    to {
        left: 7308px;
    }
}
@keyframes changePos1624_999 {
    from {
        left: -4179px;
    }

    to {
        left: 0px;
    }
}
@keyframes changePos1624shadow_999 {
    from {
        left: 0px;
    }

    to {
        left: 4179px;
    }
}
@keyframes changePos2099 {
    from {
        left: 0px;
    }

    to {
        left: -9447px;
    }
}
@keyframes changePos2099shadow {
    from {
        left: 9447px;
    }

    to {
        left: 0px;
    }
}
@keyframes changePos2099_999 {
    from {
        left: 0px;
    }

    to {
        left: -5176px;
    }
}
@keyframes changePos2099shadow_999 {
    from {
        left: 5176px;
    }

    to {
        left: 0px;
    }
}
@media (max-width: 899px) {
    .runningPageLine{
        height: 114px;
    }
    .runningPageLine h1{
        font-size: 70px;
        font-weight: 400;
        line-height: 84px;
        letter-spacing: 0em;
        text-align: left;

    }
    .runningPageLine .mainImg{
        width: 172px;
        height: 80px;
    }
    .runningPageLine .icon{
        padding-top: 17px;
    }
    .runningPageLine .arrowRight{
        width: 40px;
        height: 40px;
        border-radius: 70px;
        padding: 20px 66px;
    }
    .runnerHolder .basic.l1624{
        animation-name: changePos1624_999;
    }
    .runnerHolder .shadow.l1624{
        animation-name: changePos1624shadow_999;
    }
    .runnerHolder.left:hover{
        transition: linear;
        transform:translateX(5500px);
        transition-duration: 200000ms;
    }
    .runnerHolder.right:hover{
        transition: linear;
        transform:translateX(-7500px);
        transition-duration: 200000ms;
    }
    .runnerHolder .basic.l2099{
        animation-name: changePos2099_999;
    }
    .runnerHolder .shadow.l2099{
        animation-name: changePos2099shadow_999;
    }
}