.clientCard{
    width: 228px;
    height: 337px;
    margin-bottom: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 31px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.clientCard h3{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-bottom: 18px;
}
.clientCard p{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.5;
    white-space: pre-line;
}
.clientCard img{
    width: min-content;
}

@media (max-width: 1199px) {
    .clientCard{
        width: 340px;
    }
}
@media (max-width: 899px) {
    .clientCard{
        width: 200px;
        height: 199px;
    }
    .clientCard h3{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 18px;
    }
    .clientCard p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

    }
}
@media (max-width: 599px) {
    .clientCard{
        width: 240px;
        margin-left: auto;
        margin-right: auto;
        height: 239px;
    }

}