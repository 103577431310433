.clients{
    background-color: black;
    position: relative;
    z-index: 2;
}
.clients .background{
    display: flex;
    width: 100%;
}
.clients .background .black{
    width: 46.9%;
    background-color: #000000;
    display: flex;
    justify-content: flex-end;
    padding-top: 186px;
    position: relative;
    padding-bottom: 200px;
}
.clients .background .black .content{
    margin-left: -100px;
    margin-right: 100px;
    width: min-content;
    position: absolute;
    margin-top: 0;
    height: min-content;
}
.clients .background .black h2{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    width: min-content;
    margin-bottom: 56px;
}
.clients .background .black p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.6;
    width: 450px;

}
.clients .background .black button{
    width: 231px;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    color: white;
    margin-top: 54px;
    transition-duration: 300ms;
}
.clients .background .black button:hover{
  
    border-color: #388E3C;
}
.clients .background .grey{
    width: 53.1%;
    background-color: #191919;
    display: flex;
    justify-content: flex-start;
    padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 50px;
}
.clients .background .grey .col:first-child{
    margin-right: 20px;
}
.clients .background .grey .col:last-child{
    margin-top: 100px;
}
@media (max-width: 1199px) {
    .clients .background .black .content{
        margin-top: -50px;
    }
    .clients .background .black{
        padding-top: 136px;
        width: 50%;
    }
    .clients .background .grey{
        padding-top: 150px;
        padding-bottom: 150px;
        width: calc(50% - 50px);
    }
    .clients .background .grey .content .row{
        flex-wrap: wrap;
    }
    .clients .background .grey .content .row .col{
        margin-right: 0;
    }
    .clients .background .grey .content .row .col:last-child{
        margin-top: 0;
    }
    .clients .background .black .content{
        margin-right: 50px;
    }
    .clients .background .black p{
        width: 400px;
    }

}

@media (max-width: 899px) {
    .clients .background .grey{
        width: calc(47% - 20px);
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 20px;
    }
    .clients .background .black{
        width: 53%;
    }
    .clients .background .black p{
        width: 280px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 0;
    }
    .clients .background .black .content{
        margin-right: 40px;
    }
    .clients .background .black h2{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 28px;
    }
    .clients .background .black button{
        margin-top: 35px;
    }
    .clients .background .black button{
        width: 194px;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;

    }

}
@media (max-width: 599px) {
    .clients .background .black .content{
        margin: 0 auto;
        position: inherit;
    }
    .clients .background .black{
        height: 301px;
    }
    .clients .background{
        flex-direction: column;
    }
    .clients .background .black{
        width: 100%;
    }
    .clients .background .grey{
        width: calc(100% - 20px);
        padding-bottom: 80px;
    }
    .clients .background .black .content{
        margin-left: auto;
        margin-right: auto;
    }
    .clients .background .black{
        padding-top: 68px;
        padding-bottom: 40px;
    }
    .clients .background .black p{
        width: 300px;
    }
    .clients .background .black button{
        width: 300px;
    }
    .clients .background .grey{
        padding-top: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .clients .background .grey .content{
        width: 100%;
    }
    .clients .background .grey .content .row .col{
        width: 100%;
    }
}