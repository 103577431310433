.projectsPage{
    padding-top: 215px;
}
@media (max-width: 899px) {
    .projectsPage{
        padding-top: 110px;
    }
}
@media (max-width: 649px) {
    .projectsPage{
        padding-top: 97px
    }
}