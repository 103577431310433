.contactsContent{
    background-color: #191919;
    position: relative;
    z-index: 3;
    padding-bottom: 245px;
}
.contactsContent .firstLine{
    justify-content: space-between;
}
.contactsContent .firstLine .title h2{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.contactsContent .firstLine .fireCircle{
    background: rgba(255, 255, 255, 0.15);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-right: 40px;
}
.contactsContent .title{
    margin-top: 2px;
}
.contactsContent .firstLine > img{
    height: min-content;
    margin-right: -13px;
}

@media (max-width: 1199px) {
    .contactsContent{
        padding-bottom: 95px;
    }
}
@media (max-width: 899px) {
    .contactsContent .title{
        margin-top: 10px;
    }
    .contactsContent .firstLine .title h2{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }
    .contactsContent .firstLine .fireCircle{
        width: 40px;
        height: 40px;
        margin-top: 0;
        margin-right: 10px;
    }
    .contactsContent .firstLine .fireCircle img{
        width: 20px;
        height: 20px;
    }
}
@media (max-width: 599px) {
    .contactsContent .firstLine > img{
        display: none;
    }
    .contactsContent{
        padding-bottom: 77px;
    }
}