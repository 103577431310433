.servicesList{
    margin-top: 94px;
}
.servicesList.static{
    margin-top: 56px;
}
@media (max-width: 899px) {
    .servicesList{
        margin-top: 35px;
    }
    .servicesList.static{
        margin-top: 27px;
    }

}
@media (max-width: 599px) {
    .servicesList{
        margin-top: 53px;
    }

    .servicesList .dropdownList{

    }
}
