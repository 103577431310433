.banner{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #EAEDF0;
    overflow: hidden;
    min-height: 800px;
}
.banner .container{
    position: relative;
}

.banner .bannerLeftImg{
    position: absolute;
    left: 0;
    top: 370px;
    opacity: 0;
    width: 310px;
    height: 310px;
}
.banner .bannerTopImg{
    position: absolute;
    left: 350px;
    top:0;
    opacity: 0;
    width: 250px;
    height: 250px;
}
.banner .bannerRightImg{
    position: absolute;
    right: 0;
    top:233px;
    opacity: 0;
    width: 389px;
    height: 250px;
}
.banner .textBlock{
    position: absolute;
    right: 0;
    top: 546px;
    opacity: 0;
}
.banner .textBlock p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    width: 389px;
    margin-bottom: 24px;
}
.banner .textBlock button{
    background-color: #191919;
    width: 227px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    color: white;
    border-radius: 30px;
}
.banner .runningLine{
    position: absolute;
    left: 0;
    top: 268px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    opacity: 1;
    pointer-events: none;
    width: calc(100% + 20px);
}
.banner .runningLine h1{
    display: inline-block;
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0;
    text-align: left;
    animation: changePosInitProject 80s linear infinite;
    padding-left: 100%;
    animation-delay: -40s;
    width: max-content;
}
.banner .runningLine.shadow h1{
    animation-delay: -80s;
}

.banner .runningLine__block {
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0;
}

@keyframes changePosInitProject {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}


[data-text]::after {
    content: attr(data-text);
}

@media (max-width: 1199px) {
    .banner{
        min-height: 650px;
    }
    .banner .runningLine{
        top: 210px;
    }
    .banner .bannerTopImg{
        left: 225px;
        width: 200px;
        height: 200px;
    }
    .banner .bannerRightImg{
        top: 199px;
        width: 315px;
        height: 202px;
    }
    .banner .bannerLeftImg{
        top: 400px;
        height: 200px;
        width: 200px;
    }
    .banner .textBlock{
        top: 431px;
    }
}

@media (min-height: 1080px) {
    .banner .container{
        height: 680px;
    }
    .banner{
        display: flex;
        align-items: center;
    }
    .banner .runningLine{
        top: calc(50% - 90px)
    }
}
@media (min-height: 600px) and (max-width: 899px) {
    .banner .container{
        height: 450px;
    }
    .banner{
        display: flex;
        align-items: center;
    }
    .banner .runningLine{
        top: calc(50% - 142px) !important
    }
}
@media (min-height: 600px) and (max-width: 599px) {
    .banner .container{
        height: 600px;
    }
}
@media (max-width: 899px) {
    .banner{
        min-height: 450px;
    }
    .banner .runningLine{
        top: 134px;
    }
    .banner .runningLine h1{
        font-size: 70px;
        font-weight: 400;
        line-height: 84px;
        letter-spacing: 0;
        text-align: left;

    }
    .banner .runningLine__block {
        font-size: 70px;
        line-height: 84px;
    }
    .banner .bannerTopImg{
        left: 150px;
        width: 130px;
        height: 130px;
    }
    .banner .bannerRightImg{
        top: 90px;
        width: 180px;
        height: 150px;
        right: 100px;
    }
    .banner .bannerLeftImg{
        top: 250px;
        height: 150px;
        width: 180px;
    }
    .banner .textBlock{
        top: 261px;
        left: 320px;
    }
    .banner .textBlock p{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;

    }
    .banner .textBlock button{
        width: 191px;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;

    }
}
@media (max-width: 599px) {
    .banner{
        min-height: 640px;
    }
    .banner .bannerTopImg, .banner .bannerLeftImg, .banner .bannerRightImg{
        display: none;
    }
    .banner .runningLine{
        top: 164px;
    }
    .banner .textBlock{
        position: inherit;
        margin-top: 298px;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    .banner .textBlock p{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
    }
    .banner .textBlock button{
        width: 100%;
    }
}