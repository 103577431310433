.projectCard{
    cursor: pointer;
}

.projectCard .row{
    justify-content: space-between;
}
.projectCard .thumbnail .projectImage{
    width: 550px;
    height: 650px;
    transition-duration: 500ms;
}
.projectCard .thumbnail{
    width: 550px;
    height: 650px;
    margin-bottom: 20px;
    overflow: hidden;
}

.projectCard p{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;

}
.projectCard label{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.5;
    display: block;
}
.projectCard .arrowRight{
    opacity: 0;
    transition-duration: 500ms;
}
.projectCard:hover .arrowRight{
    opacity: 1;
}
.projectCard:hover .projectImage{
    transform:scale(1.06);
}
.projectCard .text{
    transition-duration: 500ms;
}
.projectCard:hover .text{
    margin-left: 20px;
}
.projectCard{
    margin-top: -50px;
}
.projectCardOdd{
    margin-top: 100px;
}
.projects a:nth-child(2) .projectCardOdd{
    margin-top: 150px;
}
.projects a:first-child .projectCard{
    margin-top: 0px;
}

@media (max-width: 1199px) {
    .projects a:nth-child(2) .projectCardOdd{
        margin-top: 60px;
    }
    .projectCard{
        margin-top: -5px;

    }
    .projectCard .thumbnail, .projectCard .thumbnail .projectImage{
        width: 420px;
        height: 520px;
    }
    .projectCardOdd{
        margin-top: 55px;
    }
}
@media (max-width: 899px) {
    .projectCardOdd{
        margin-top: 33px;
    }
    .projectCard{
        margin-bottom: 0!important;
    }
}
@media (max-width: 599px) {
    .projectCard, .projectCardOdd, .projects a:nth-child(2) .projectCardOdd{
        margin-top: 35px;
        margin-bottom: 0;
    }
}