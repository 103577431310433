.contactsPage{
    background-color: #191919;
    padding-top: 215px;
}
@media (max-width: 1199px) {
    .contactsPage{
        padding-top: 170px;
    }
}
@media (max-width: 899px){
    .contactsPage{
        padding-top: 98px;
    }
}