.nextProject{
    background-color: black;
    height: 783px;
    position: relative;
    padding-top: 97px;
    overflow: hidden;
}
.nextProject .runningLine{
    position: absolute;
    top: 295px;
    opacity: 1;
    z-index: 2;

    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    width: calc(100% + 20px);
}
.nextProject .container{
    position: relative;
}
.nextProject .runningLine h1{
    display: inline-block;
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0;
    text-align: left;
    width: max-content;
    animation: changePosInitProject 200s linear infinite;
    padding-left: 100%;
    animation-delay: -100s;
    color: white;
}
.nextProject .runningLine.shadow h1{
    animation-delay: -200s;
}
.nextProject .runningLine__block {
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0;
    color: white;
}
.nextProject .buttonNext{
    position: absolute;
    right: 0;
}
.nextProject .buttonNext a{
    display: flex;
    align-items: center;
}
.nextProject .buttonNext a p{
    color: #FFFFFF80;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    margin-right: 12px;
    transition-duration: 300ms;

}
.nextProject .buttonNext a img{
    opacity: 0.5;
    transition-duration: 300ms;
}
.nextProject button{
    width: 100%;
    color: white;
    margin-top: 620px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    border-radius: 30px;
    border: 1px solid #FFFFFF33;
    height: 58px;
}
.nextProject .nextImg{
    position: absolute;
    width: 420px;
    height: 570px;
    top: 100px;
    left: calc(50% - 210px);
}
.nextProject .filledFromLeft span{
    position: absolute;
    left: calc(50% - 66px);
    top: 21px;
}
.nextProject .filledFromLeft .buttonBackLong{
    top: 0;
}
.nextProject:hover .buttonNext a p{
    color: white;
    margin-right: 22px;
}
.nextProject:hover .buttonNext a img{
    opacity: 1;
    margin-right: -10px;
}
@media (max-width: 1199px) {
    .nextProject{
        padding-top: 60px;
        height: 610px;
    }
    .nextProject .nextImg{
        width: 320px;
        height: 450px;
        top: 60px;
        left: calc(50% - 160px);
    }
    .nextProject .runningLine{
        top: 195px;
    }
    .nextProject button{
        margin-top: 490px;
    }
}

@media (max-width: 899px) {
    .nextProject{
        height: 470px;
    }
    .nextProject .nextImg{
        width: 230px;
        height: 320px;
        left: calc(50% - 115px);
    }
    .nextProject .runningLine{
        top: 178px;
    }
    .nextProject .runningLine h1{
        font-size: 70px;
        font-weight: 400;
        line-height: 84px;
        letter-spacing: 0;
        text-align: left;

    }
    .nextProject .runningLine__block {
        font-size: 70px;
        line-height: 84px;
    }
    .nextProject button{
        margin-top: 360px;
        height: 50px;
    }
    .nextProject .filledFromLeft span{
        top: 17px;
    }
    .nextProject .buttonNext a p{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;

    }
    .buttonBackLong{
        height: 50px;
    }
    .nextProject .filledFromLeft span{
        left: calc(50% - 46px);
    }
}
@media (max-width: 599px) {
    .nextProject{
        height: 582px;
    }
    .nextProject .nextImg{
        top: 124px;
        width: 270px;
        height: 368px;
        left: calc(50% - 135px);
    }
    .nextProject .runningLine{
        top: 266px;
    }
    .nextProject button{
        margin-top: 472px;
    }

}