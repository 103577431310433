.dropdownList{
    max-height: 158px;
    overflow: hidden;
    height: 1%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    transition-duration: 500ms;
}
.servicesList > div:last-child > .dropdownList{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.dropdownList > .row{
    padding-top: 31px;
    padding-bottom: 31px;
    cursor: pointer;
    align-items: center;
}
.dropdownList > .row.notPointed{
    cursor: auto!important;
}
.dropdownList > .row label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.5;
    margin-right: 22px;
    margin-bottom: 48px;
    transition-duration: 300ms;
}
.dropdownList > .row h2{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;

}
.dropdownList > .row p{
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.6;
    width: 304px;
    margin-right: 50px;

}
.dropdownList .cross{
    position: relative;
    width: 32px;
    height: 32px;
    transition-duration: 300ms;
}
.dropdownList .cross .vertPart, .dropdownList .cross .horizPart{
    background-color: black;
    position: absolute;
    transition-duration: 300ms;
}
.dropdownList .cross .vertPart{

    left: 15px;
    width: 2px;
    height: 32px;
}
.dropdownList .cross .horizPart{
    width: 32px;
    height: 2px;
    top: 15px;
}
.dropdownList:hover > .row label{
    margin-right: 32px;
}
.dropdownList:hover p{
    color: black;
    opacity: 1;
    transition-duration: 300ms;
}
.dropdownList:hover .cross{
    margin-right: 10px;
}
.dropdownList:hover .cross .vertPart, .dropdownList:hover .cross .horizPart{
    background-color: #388E3C;
}
.dropdownList.opened > .row label{
    margin-right: 22px;
}
.dropdownList.opened .cross{
    margin-right: 0;
}
.dropdownList.opened .cross .vertPart, .dropdownList.opened .cross .horizPart{
    background-color: #000000;
}
.dropdownList.opened{
    height: 100%;
    max-height: 1000px;
}
.dropdownList.opened .cross .vertPart{
    transform: rotate(90deg);
}
.dropdownList .stage{
    width: 600px;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.dropdownList .stage p, .dropdownList .stage label{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: none;
    color: black;
}
.dropdownList .stage label{
    opacity: 0.5;
}
.dropdownList.static{

}
.dropdownList.opened.static .cross{
    display: none;
}
.dropdownList.opened.static > .row p{
    margin-right: 0;
}
.dropdownList.opened.static > .row{
    padding-top: 42px;
}

.dropdownList > .row > .row{
    align-items: center;
    width: 100%;
}
@media (max-width: 1199px) {
    .dropdownList > .row{
        align-items: flex-start;
        padding-bottom: 48px;
    }
    .dropdownList > .row > .row{
        flex-direction: column;
        align-items: flex-start;
    }
    .dropdownList{
        max-height: 220px;
    }
    .dropdownList > .row label{
        margin-top: 14px;
    }
    .dropdownList .cross{
        margin-top: 28px;
        margin-left: -70px;
    }
    .dropdownList > .row p{
        margin-left: -38px;
        margin-top: 6px;
        width: 450px;
        height: 40px;
    }
    .dropdownList .list{
        transition-duration: 500ms;
    }
    .dropdownList.opened .list{
        margin-top: -16px;
    }
}
@media (max-width: 899px) {
    .dropdownList{
        max-height: 113px;
    }
    .dropdownList > .row{
        padding-top: 23px;
        padding-bottom: 26px;
    }
    .dropdownList > .row h2{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }
    .dropdownList > .row p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        margin-top: 8px;
        min-height: 20px;
        height: auto;
    }
    .dropdownList > .row label{
        margin-right: 15px;
        margin-top: 5px;
    }
    .dropdownList .cross{
        margin-top: 8px;
        width: 20px;
        height: 20px;
        margin-left: -50px;
    }
    .dropdownList .cross .horizPart{
        width: 20px;
        height: 2px;
        top: 9px;
    }
    .dropdownList .cross .vertPart{
        width: 2px;
        height: 20px;
        left: 9px;
    }
    .dropdownList > .row p{
        margin-left: -29px;
    }

    .dropdownList.opened.static > .row{
        padding-top: 23px;
    }
    .dropdownList .stage{
        width: 300px;
    }
    .dropdownList .stage p, .dropdownList .stage label{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

    }
    .dropdownList.opened .list{
        margin-top: -11px;
    }
}
@media (max-width: 599px) {
    .dropdownList{
        max-height: 133px;
    }
    .dropdownList > .row p{
        width: 300px;
    }
    .dropdownList > .row > .row{
        width: 244px;
    }
    .dropdownList .stage p, .dropdownList .stage label{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: right;

    }
    .dropdownList.opened > .row label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 13px;
    }
    .dropdownList .cross{
        margin-left: 0;
    }
    .dropdownList:hover .cross{
        margin-left: -10px;
    }
    .dropdownList:hover > .row label{
        margin-right: 22px;
    }
}