.contactsForm {
    display: flex;

    justify-content: space-between;
    margin-top: 81px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 96px;
    color: white
}
.contactsForm h5{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 71px;
}
.contactsForm .fields p{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 37px;
    text-transform: uppercase;

}
.contactsForm .fields{
    width: 650px;
}
.contactsForm .contacts{
    width: 389px;
}
.contactsForm .fields .animatedInput{
    margin-bottom: 30px;
}
.contactsForm .fields .animatedInput:last-child{
    margin-bottom: 0;
}
.contactsForm .fields .info, .contactsForm .fields .service, .contactsForm .fields .info, .contactsForm .fields .budget{
    margin-bottom: 77px;
}
.contactsForm .fields .service .workTypes{
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    width: 629px;
}
.contactsForm .fields .service .worksType{
    padding: 19px 38px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    cursor: pointer;
    transition-duration: 300ms;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
}
@media(hover: hover) {
  .contactsForm .fields .service .worksType:hover{
    background-color: #388E3C;
    border-color: #388E3C;
  }
}
.contactsForm .fields .service .worksType.active{
    background-color: #388E3C;
}
.contactsForm .fields .budget .animatedInput{
    margin-bottom: 22px;
}
.contactsForm .fields .budget .animatedCheckbox{
    position: relative;
    width: 16px;
    height: 16px;
    border: 2px solid white;
    cursor: pointer;
}
.contactsForm .fields .budget .animatedCheckbox input{
    position: absolute;
    left: -2px;
    top: -2px;
    width: 20px;
    height: 20px;
    margin: 0;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
}
.contactsForm .fields .budget .animatedCheckbox img{
    position: absolute;
    top: -2px;
    left: -2px;
    filter: invert(1);
}
.contactsForm .fields .budget .row p{
    margin-bottom: 0;
    margin-left: 19px;
}
.contactsForm .fields .budget .row{
    align-items: center;
}

.contactsForm .fields .task textarea{
    width: calc(100% - 60px);
    height: 150px;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0;
    outline: none;
    resize: none;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    padding: 25px 30px;
    cursor: pointer;
    transition-duration: 300ms;
    margin-bottom: 40px;
}
.contactsForm .fields .task textarea:hover, .contactsForm .fields .task textarea:focus{
    border-color: rgba(255, 255, 255, 0.4);
}
.contactsForm .fields  .task textarea:focus::-webkit-input-placeholder{
    opacity: 0;
}
.contactsForm .fields  .task textarea:focus:-moz-placeholder {
    opacity: 0;
}
.contactsForm .fields  .task textarea:focus::-moz-placeholder {
    opacity: 0;
}
.contactsForm .fields  .task textarea:focus:-ms-input-placeholder{
    opacity: 0;
}
.contactsForm .fields  .task textarea:focus::-ms-input-placeholder {
    opacity: 0;
}
.contactsForm .fields  .task textarea:focus::placeholder {
    opacity: 0;
}

.contactsForm .fields  .task textarea::-webkit-input-placeholder {
    color: #FFFFFF80;
    transition-duration: 300ms;
}
.contactsForm .fields  .task textarea:hover::-webkit-input-placeholder {
    color:    white;
}

.contactsForm .fields  .task textarea:-moz-placeholder {
    color: #FFFFFF80;
    opacity:  1;
    transition-duration: 300ms;
}
.contactsForm .fields  .task textarea:hover:-moz-placeholder {
    color:    white;
    opacity:  1;
}

.contactsForm .fields  .task textarea::-moz-placeholder {
    color: #FFFFFF80;
    opacity:  1;
    transition-duration: 300ms;
}
.contactsForm .fields  .task textarea:hover::-moz-placeholder {
    color:    white;
    opacity:  1;
}

.contactsForm .fields  .task textarea:-ms-input-placeholder{
    color: #FFFFFF80;
    transition-duration: 300ms;
}
.contactsForm .fields  .task textarea:hover:-ms-input-placeholder {
    color:    white;
}

.contactsForm .fields  .task textarea::-ms-input-placeholder {
    color: #FFFFFF80;
    transition-duration: 300ms;
}
.contactsForm .fields  .task textarea:hover::-ms-input-placeholder {
    color:    white;
}

.contactsForm .fields  .task textarea::placeholder {
    color: #FFFFFF80;
    transition-duration: 300ms;
}
.contactsForm .fields  .task textarea:hover::placeholder {
    color:    white;
}
.contactsForm .fields .file{
    width: 100%;
    /*margin-bottom: 190px;*/
}
.contactsForm .fields  .file label{
    padding: 19px 38px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    transition-duration: 300ms;
    cursor: pointer;
}
.contactsForm .fields  .file label:hover{
    background-color: #388E3C;
    border-color: #388E3C;
}
.contactsForm .fields .send{
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 25px;
    position: relative;
    margin-top: 190px;
}
.contactsForm .fields .send label{
    width: 330px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    display: block;
    color: #FFFFFF99;
}

.contactsForm .fields .send button {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    background: #388E3C;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 300ms;

}
.contactsForm .fields .send button p{
    z-index: 3;
    position: absolute;
    margin: 0;
}
.contactsForm .fields .send button .buttonBack{
    background-color: #569F59;
}
.contactsForm .absHolder{
    top: -110px;
    left: 500px;

    position: absolute;
}
.contactsForm .contacts{
    display: flex;
    flex-direction: column;
    height: min-content;
}
.contactsForm .contacts label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF99;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.contactsForm .contacts a{
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 55px;

}
.contactsForm .contacts .row{
    width: 173px;
    justify-content: space-between;
}
.contactsForm .contacts .row a{
    transition-duration: 300ms;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;

}
.contactsForm .contacts .row a:hover{
    border-color: white;

}
.contactsForm .fields .file{
    position: relative;
    display: flex;
}
.contactsForm .fields .file .filename{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF80;
    margin-top: 1px;
    margin-right: 5px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.contactsForm .fields .file .row{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.contactsForm .fields .send .contactsForm_error,
.contactsForm .fields .send .contactsForm_success {
  position: absolute;
  top: -60px;
  left: 50px;
  text-align: center;
  margin: 0;
}

.contactsForm .fields .send .contactsForm_error {
  color: #ff9999;
}

.contactsForm .fields .send .contactsForm_success {
  color: #63c268;
}

@media (max-width: 1199px) {
    .contactsForm{
        margin-top: 41px;
        padding-top: 56px;
    }
    .contactsForm .fields{
        width: 451px;
    }
    .contactsForm h5{
        margin-bottom: 41px;
    }
    .contactsForm .fields .info, .contactsForm .fields .service, .contactsForm .fields .info, .contactsForm .fields .budget{
        margin-bottom: 56px;
    }
    .contactsForm .fields .service .workTypes{
        width: 474px;
    }
    .contactsForm .fields p{
        margin-bottom: 27px;
    }
    .contactsForm .fields .send button{
        left: 210px;
    }
    .contactsForm .fields .send{
        margin-top: 167px;
        padding-top: 130px;
        width: 450px;
    }
    .contactsForm .absHolder{
        transform: none!important;
        left: 0;
    }
    .contactsForm .fields .file .filename {
        max-width: 180px;
    }
    .contactsForm .fields .send .contactsForm_error,
    .contactsForm .fields .send .contactsForm_success{
      top: -100px;
      left: 0;
    }
}
@media (max-width: 899px) {
    .contactsForm .fields .file {
      flex-direction: column;
    }
    .contactsForm .fields .file .row{
      margin-top: 26px;
    }
    .contactsForm{
        padding-top: 47px;
    }
    .contactsForm .fields{
        width: 280px;
    }
    .contactsForm .contacts{
        width: 280px;
    }
    .contactsForm h5{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 34px;
    }
    .contactsForm .fields p{
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0;
        text-align: left;

        margin-bottom: 27px;

    }
    .contactsForm .fields .info p{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;

    }
    .contactsForm .fields .animatedInput{
        margin-bottom: 15px;

    }
    .contactsForm .fields .animatedInput input{
        width: 280px;
    }
    .contactsForm .fields .service .workTypes{
        width: 260px;
    }
    .contactsForm .fields .service .worksType{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        padding: 16px 29px;
    }
    .contactsForm .fields .info, .contactsForm .fields .service, .contactsForm .fields .info, .contactsForm .fields .budget{
        margin-bottom: 46px;
    }
    .contactsForm .fields .budget .animatedInput{
        margin-bottom: 17px;
    }
    .contactsForm .fields .budget .animatedInput input{
        height: 71px;
    }
    .contactsForm .fields .budget p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
    }
    .contactsForm .fields .task p{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;

    }
    .contactsForm .fields .task textarea{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        height: 100px
    }
    .contactsForm .fields .file label{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        padding: 16px 27px;
    }
    .contactsForm .absHolder{
        left: 50px;
        top: -100px
    }
    .contactsForm .fields .send button{
        left: 0;
        height: 200px;
        width: 200px;

    }
    .contactsForm .fields .send{
        width: 300px;
        margin-top: 200px;
    }
    .contactsForm .fields .send label{
        width: 300px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
    }
    .contactsForm .contacts label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 13px;
    }
    .contactsForm .contacts a{
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 28px;
    }
    .contactsForm .contacts .row{
        width: 156px;
    }
    .contactsForm .contacts label:nth-child(2){
        margin-bottom: 10px;
    }
    .contactsForm .fields .send .contactsForm_error,
    .contactsForm .fields .send .contactsForm_success {
        width: 100%;
        top: -145px;
    }
}
@media (max-width: 599px) {
    .contactsForm{
        flex-direction: column-reverse;
    }
    .contactsForm .fields{
        width: 300px;
        margin-top: 28px;
        padding-top: 46px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);;
    }
    .contactsForm .fields .animatedInput input{
        width: 300px;
    }
    .contactsForm .fields .file{
        width: 300px;

    }
    .contactsForm .fields .file label{
        padding: 16px 80px;
    }
    .contactsForm .fields .send{
        padding-top: 119px;
    }
    .contactsForm .fields .file .row{
      margin-left: auto;
      margin-right: auto;
    }
}