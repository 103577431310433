.animatedInput input{
    width: 100%;
    height: 73px;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    color: white;
    outline: none!important;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    transition-duration: 300ms;

}
.animatedInput{
    position: relative;
    cursor: pointer;
}
.animatedInput label{
    position: absolute;
    top: 24px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    transition-duration: 300ms;
    cursor: pointer;
}
.animatedInput:hover label{
    color: white;
}
.animatedInput:hover input{
    border-color: rgba(255, 255, 255, 0.4);
}
.animatedInput input:focus ~ label, .animatedInput input:not(:placeholder-shown) ~ label{
    color: rgba(255, 255, 255, 0.5);
    top: 1px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;

}
.animatedInput input:focus, .animatedInput input:not(:placeholder-shown){
    height: 25px;
    padding-top: 28px;
    padding-bottom: 22px;
    border-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 899px) {
    .animatedInput input{
        height: 49px;
    }
    .animatedInput label{
        top: 14px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

    }

}
@media (max-width: 640px){
    .animatedInput input{
        height: 50px;
    }
    .animatedInput input:focus, .animatedInput input:not(:placeholder-shown){
        height: 22px;
        padding-top:20px;
        padding-bottom: 10px;
    }
}